import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { Config } from '../../data/config'
import { NavigationOption } from './Navigation'
import { url } from '../../misc/url'

export const Footer = () => {
  const router = useRouter()
  const { t } = useTranslation()

  const navigation: NavigationOption[] = [
    {
      styleClass:
        'col-span-6 row-start-2 md:col-span-4 md:row-start-1 lg:col-span-2 lg:row-start-1',
      name: t('Navigation.solutions'),
      href: '#',
      newTab: false,
      options: [
        {
          name: t('Navigation.gigPlatforms'),
          href: url(Config.palencaLandingHost, '/soluciones/plataformas-gig'),
          newTab: false
        },
        {
          name: t('Navigation.gobServices'),
          href: url(
            Config.palencaLandingHost,
            '/soluciones/servicios-gubernamentales'
          ),
          newTab: false
        }
      ]
    },
    {
      styleClass:
        'col-span-6 row-start-2 md:col-span-4 md:row-start-1 lg:col-span-2 lg:row-start-1',
      name: t('Navigation.developers'),
      href: '#',
      newTab: false,
      options: [
        {
          name: t('Navigation.apiRef'),
          href: url(Config.palencaDevelopersHost, '/docs/Quickstart'),
          newTab: false
        },
        {
          name: t('Navigation.demo'),
          href: url(Config.palencaDemoHost),
          newTab: false
        },

        {
          name: t('Navigation.systemStatus'),
          href: url(Config.palencaStatusHost),
          newTab: false
        }
      ]
    },
    {
      styleClass:
        'col-span-6 row-start-3 md:col-span-4 md:row-start-2 lg:col-span-2 lg:row-start-1',
      name: t('Navigation.company'),
      href: '#',
      newTab: false,
      options: [
        {
          name: t('Navigation.aboutUs'),
          href: url(Config.palencaLandingHost, '/about'),
          newTab: false
        },
        {
          name: t('Navigation.companies'),
          href: url(Config.palencaLandingHost, '/empresas'),
          newTab: false
        },
        {
          name: t('Navigation.blog'),
          href: url(Config.palencaBlogHost),
          newTab: false
        },
        {
          name: t('Navigation.contactUs'),
          href: url(Config.palencaLandingHost, '/contacto'),
          newTab: false
        },
        {
          name: t('Navigation.careers'),
          href: url(
            Config.palencaNotionHost,
            '/nete-a-Palenca-600cce91f9f84be381a35bae99a174e1'
          ),
          newTab: false
        }
      ]
    },
    {
      styleClass:
        'col-span-6 row-start-3 md:col-span-4 md:row-start-2 lg:col-span-2 lg:row-start-1',
      name: t('Navigation.legal'),
      href: '#',
      newTab: false,
      options: [
        {
          name: t('Navigation.privacyPolicy'),
          href: url(Config.palencaLandingHost, '/privacy'),
          newTab: false
        }
      ]
    }
  ]

  const social = [
    {
      name: 'Twitter',
      href: Config.twitterHref,
      icon: (props: any) => (
        <img src="/images/icons/twt.svg" alt="twitter" {...props} />
      )
    },
    {
      name: 'Instagram',
      href: Config.instagramHref,
      icon: (props: any) => (
        <img src="/images/icons/ig.svg" alt="twitter" {...props} />
      )
    },
    {
      name: 'LinkedIn',
      href: Config.linkedinHref,
      icon: (props: any) => (
        <img src="/images/icons/ld.svg" alt="twitter" {...props} />
      )
    }
  ]

  const handleLocaleChange = (event: any) => {
    const value = event.target.value

    router.push(router.route, router.asPath, {
      locale: value
    })
  }

  return (
    <>
      <div className="bg-[#111d30] py-[80px] font-inter text-[#fff] lg:mt-[80px]">
        <div className="px-[24px] lg:mx-[48px] 2xl:mx-[288px]">
          <h2 className="text-[36px] md:text-[48px] lg:text-[48px] font-semibold">
            {t('FooterTop.ready')}
          </h2>
          <p className="my-[24px] opacity-[0.8] md:w-[65%] lg:w-[65%]">
            {t('FooterTop.description')}
          </p>
          <a
            href={url(Config.palencaLandingHost, '/contacto')}
            className="inline-block rounded-[8px] border-[2px] border-[#2b46b9] bg-[#2b46b9] py-[10px] px-[22px] text-[14px]"
          >
            {t('FooterTop.startDemo')}
          </a>
        </div>
      </div>

      <div className="bg-[#111d30] py-[60px] font-inter font-[350]">
        <div className="grid auto-rows-auto grid-cols-12 px-[24px] font-inter text-[16px] text-[#eaeef2] md:grid-rows-3 lg:mx-[48px] lg:grid-rows-1 2xl:mx-[288px] gap-y-[64px] max-[420px]:gap-x-[20px]">
          <div className="md:col-row-start-1 col-span-6 row-start-1 mb-[20px] md:col-span-4 lg:col-span-4">
            <Link href={url(Config.palencaLandingHost)} passHref>
              <img
                src="/images/logos/logo_palenca_white.svg"
                alt="Palenca Logo"
              />
            </Link>
            <div className="mt-[20px] flex gap-[24px]">
              {social.map((item) => (
                <Link key={item.name} href={item.href} passHref>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex text-gray-400 hover:text-gray-900"
                  >
                    <span className="sr-only">{item.name}</span>
                    <item.icon
                      className="h-[24px] w-[24px]"
                      aria-hidden="true"
                    />
                  </a>
                </Link>
              ))}
            </div>
          </div>
          {navigation.map((item) => (
            <div key={item.name} className={item.styleClass}>
              <div className="mb-[8px]">{item.name}</div>
              {item.options.map((option) => (
                <a
                  href={option.href}
                  key={option.name}
                  className="block pt-[12px] text-[14px] text-[#667387]"
                >
                  {option.name}
                </a>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
