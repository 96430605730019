import { FC, ReactElement } from 'react'
import { classNames } from '../../misc/css'
import { useTranslation } from 'next-i18next'

interface WidgetStep {
  content: string
  currentStep?: number
}

interface WidgetStepsProps {
  title: ReactElement
  steps?: WidgetStep[]
}

const defaultSteps = [
  {
    content: 'Demo.Steps.step1'
  },
  {
    content: 'Demo.Steps.step2'
  },
  {
    content: 'Demo.Steps.step3'
  },
  {
    content: 'Demo.Steps.step4'
  }
]

export const WidgetSteps: FC<WidgetStepsProps> = ({
  title,
  steps = defaultSteps
}) => {
  const { t } = useTranslation()

  return (
    <>
      <h2 className="mt-8 text-lg font-semibold uppercase tracking-wide text-primary-dark-900 lg:mt-16">
        {title}
      </h2>
      <div className="mt-8 flow-root">
        <ul role="list" className="-mb-8">
          {steps.map((step, stepIdx) => (
            <li key={stepIdx}>
              <div className="relative pb-6">
                <div className="relative flex items-center justify-center space-x-3">
                  <div>
                    <span
                      className={classNames(
                        'flex h-10 w-10 items-center justify-center rounded-full bg-primary-blue-100 text-sm font-semibold'
                      )}
                    >
                      {stepIdx + 1}
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      <p className="text-base text-primary-dark-900">
                        {t(step.content)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}
