export const dummyEarnings = {
  ar: [
    {
        "amount": 3003,
        "currency": "ars",
        "earning_date": "2023-05-12",
        "cash_amount": null,
        "count_trips": 6
    },
    {
      "amount": 2465,
      "currency": "ars",
      "earning_date": "2023-05-11",
      "cash_amount": null,
      "count_trips": 9
    },
    {
      "amount": 3071,
      "currency": "ars",
      "earning_date": "2023-05-10",
      "cash_amount": null,
      "count_trips": 6
    },
    {
      "amount": 3133,
      "currency": "ars",
      "earning_date": "2023-05-09",
      "cash_amount": null,
      "count_trips": 6
    },
  ],
  br: [
    {
      "amount": 68,
      "currency": "brl",
      "earning_date": "2023-05-08",
      "cash_amount": null,
      "count_trips": 9
    },
    {
      "amount": 65,
      "currency": "brl",
      "earning_date": "2023-05-07",
      "cash_amount": null,
      "count_trips": 6
    },
    {
      "amount": 21,
      "currency": "brl",
      "earning_date": "2023-05-06",
      "cash_amount": null,
      "count_trips": 9
    },
    {
      "amount": 62,
      "currency": "brl",
      "earning_date": "2023-05-05",
      "cash_amount": null,
      "count_trips": 6
    },
  ],
  cl: [
    {
      "amount": 5559,
      "currency": "clp",
      "earning_date": "2023-05-12",
      "cash_amount": null,
      "count_trips": 6
    },
    {
      "amount": 4022,
      "currency": "clp",
      "earning_date": "2023-05-11",
      "cash_amount": null,
      "count_trips": 9
    },
    {
      "amount": 4825,
      "currency": "clp",
      "earning_date": "2023-05-10",
      "cash_amount": null,
      "count_trips": 9
    },
    {
      "amount": 5697,
      "currency": "clp",
      "earning_date": "2023-05-09",
      "cash_amount": null,
      "count_trips": 6
    },
  ],
  co: [
    {
      "amount": 54017,
      "currency": "cop",
      "earning_date": "2023-05-12",
      "cash_amount": null,
      "count_trips": 9
    },
    {
      "amount": 62335,
      "currency": "cop",
      "earning_date": "2023-05-11",
      "cash_amount": null,
      "count_trips": 6
    },
    {
      "amount": 40800,
      "currency": "cop",
      "earning_date": "2023-05-10",
      "cash_amount": null,
      "count_trips": 6
    },
    {
      "amount": 31073,
      "currency": "cop",
      "earning_date": "2023-05-09",
      "cash_amount": null,
      "count_trips": 6
    },
  ],
  mx: [
    {
      "amount": 103,
      "currency": "mxn",
      "earning_date": "2023-05-12",
      "cash_amount": null,
      "count_trips": 9
    },
    {
      "amount": 152,
      "currency": "mxn",
      "earning_date": "2023-05-11",
      "cash_amount": null,
      "count_trips": 9
    },
    {
      "amount": 297,
      "currency": "mxn",
      "earning_date": "2023-05-10",
      "cash_amount": null,
      "count_trips": 6
    },
    {
      "amount": 232,
      "currency": "mxn",
      "earning_date": "2023-05-09",
      "cash_amount": null,
      "count_trips": 6
    },
  ],
  pe: [
    {
      "amount": 60,
      "currency": "pen",
      "earning_date": "2023-05-13",
      "cash_amount": null,
      "count_trips": 6
    },
    {
      "amount": 16,
      "currency": "pen",
      "earning_date": "2023-05-12",
      "cash_amount": null,
      "count_trips": 9
    },
    {
      "amount": 37,
      "currency": "pen",
      "earning_date": "2023-05-11",
      "cash_amount": null,
      "count_trips": 6
    },
    {
      "amount": 40,
      "currency": "pen",
      "earning_date": "2023-05-10",
      "cash_amount": null,
      "count_trips": 6
    },
  ]
}


export const platformsWithEarnings = [
  "clip",
  "picap",
  "cabify",
  "pedidos_ya",
  "cornershop",
  "mensajeros_urbanos",
  "didi",
  "rappi",
  "ifood",
  "indriver",
  "didi_food",
  "noventa_nove",
  "osmos",
  "uber",
  "uber_eats"
]
