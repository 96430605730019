export const trimRight = (word: string, char: string) => {
  return word.replace(new RegExp('[' + char + ']+$'), '')
}

export const trimLeft = (word: string, char: string) => {
  return word.replace(new RegExp('^[' + char + ']+'), '')
}

export const trim = function (word: string, char: string) {
  return trimRight(trimLeft(word, char), char)
}
