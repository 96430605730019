import { RadioGroup } from '@headlessui/react'
import { classNames } from '../../misc/css'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'

export enum EnvironmentType {
  sandbox = 'sandbox',
  production = 'production'
}

export interface EnvironmentOption {
  type: EnvironmentType
  name: string
  description: string
}

export type EnvironmentDictionary = Record<EnvironmentType, EnvironmentOption>

const defaultEnvironments: EnvironmentDictionary = {
  [EnvironmentType.sandbox]: {
    type: EnvironmentType.sandbox,
    name: 'Demo.Environment.sandboxTitle',
    description: 'Demo.Environment.sandboxDescription'
  },
  [EnvironmentType.production]: {
    type: EnvironmentType.production,
    name: 'Demo.Environment.productionTitle',
    description: 'Demo.Environment.productionDescription'
  }
}

export interface WidgetEnvironmentsProps {
  environments?: EnvironmentDictionary
  selected?: EnvironmentType.sandbox
  onChange?: (selection: EnvironmentOption) => void
}

export const WidgetEnvironments: FC<WidgetEnvironmentsProps> = ({
  environments = defaultEnvironments,
  selected = EnvironmentType.sandbox,
  onChange
}) => {
  const { t } = useTranslation()
  const [selection, setSelection] = useState(selected as EnvironmentType)
  const options = useMemo(() => Object.values(environments), [environments])

  const handleSelected = (event: EnvironmentType) => setSelection(event)

  useEffect(() => {
    onChange && onChange(environments[selection])
  }, [selection])

  useEffect(() => {
    setSelection(selected)
  }, [selected])

  return (
    <>
      <h2 className="mb-4 text-lg font-semibold uppercase tracking-wide text-primary-dark-900 ">
        {t('Demo.Environment.title')}
      </h2>
      <RadioGroup value={selection} onChange={handleSelected}>
        <div className="space-y-4 rounded-md bg-white">
          {options.map((setting: EnvironmentOption) => (
            <RadioGroup.Option
              key={setting.name}
              value={setting.type}
              className={({ checked }) =>
                classNames(
                  checked
                    ? 'border-primary-blue-500 bg-primary-blue-100'
                    : 'border-base-border',
                  'relative flex cursor-pointer rounded-lg border p-4 focus:outline-none'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span
                    className={classNames(
                      checked
                        ? 'border-transparent bg-primary-blue-900'
                        : 'border-primary-base-border bg-white',
                      active ? '' : '',
                      'mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border'
                    )}
                    aria-hidden="true"
                  >
                    <span className="h-1.5 w-1.5 rounded-full bg-white" />
                  </span>
                  <span className="ml-3 flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className={classNames(
                        checked ? 'text-dark-900' : 'text-dark-900',
                        'block text-base font-semibold'
                      )}
                    >
                      {t(setting.name)}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={classNames(
                        checked ? 'text-dark-900' : 'text-dark-900',
                        'mt-1 block text-sm'
                      )}
                    >
                      {t(setting.description)}
                    </RadioGroup.Description>
                  </span>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  )
}
