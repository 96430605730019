import { Spinner } from '@palenca/ui'

export const Loading = () => {
  return (
    <div className="flex h-full bg-white">
      <div className="m-auto">
        <Spinner />
      </div>
    </div>
  )
}
