import { trimLeft, trimRight } from './trim'

export const url = (
  host: string,
  path: string = '',
  params: Record<string, string> = {}
) => {
  const trimmedHost = trimRight(host, '/')
  const trimmedPath = trimLeft(path, '/')
  const segments = [trimmedHost, trimmedPath].filter((s) => s.length)

  const uri = new URL(segments.join('/'))

  Object.entries(params).forEach(([key, value]) => {
    uri.searchParams.append(key, String(value))
  })

  return uri.href
}
