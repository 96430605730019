export const CaretIcon = ({ size = 18, color = "#111D30" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
  >
    <path
      d="M8.87165 11.5871C8.67303 11.8077 8.32697 11.8077 8.12835 11.5871L4.75103 7.83448C4.46145 7.51272 4.6898 7 5.12268 7L11.8773 7C12.3102 7 12.5386 7.51272 12.249 7.83448L8.87165 11.5871Z"
      fill={color}
    />
  </svg>
);
