import { useState } from 'react'
import { useTranslation } from 'next-i18next'

import { Config } from '../../data/config'
import { CaretIcon } from './CaretIcon'
import { url } from '../../misc/url'

export interface NavigationOption {
  styleClass?: string
  name: string
  href: string
  newTab: boolean
  options: Omit<NavigationOption, 'options'>[]
}

export const Navigation = () => {
  const { t } = useTranslation('common')
  const [open, setOpen] = useState(false)

  const [currentOption, setCurrentOption] = useState<string | null>(null)
  const [isNavigationOpen, setIsNavigationOpen] = useState(false)

  const openMenu = (name: string) => {
    if (name == currentOption) {
      setOpen(!open)
    } else {
      setOpen(true)
    }
    setCurrentOption(name)
  }

  const handleOpenNavigation = () => {
    setIsNavigationOpen(!isNavigationOpen)
  }

  const navigation: NavigationOption[] = [
    {
      name: t('Navigation.solutions'),
      href: url(Config.palencaLandingHost, '/soluciones/plataformas-gig'),
      newTab: false,
      options: [
        {
          name: t('Navigation.gigPlatforms'),
          href: url(Config.palencaLandingHost, '/soluciones/plataformas-gig'),
          newTab: false
        },
        {
          name: t('Navigation.gobServices'),
          href: url(
            Config.palencaLandingHost,
            '/soluciones/servicios-gubernamentales'
          ),
          newTab: false
        }
      ]
    },
    {
      name: t('Navigation.demo'),
      href: url(Config.palencaDemoHost),
      newTab: false,
      options: []
    },
    {
      name: t('Navigation.developers'),
      href: url(Config.palencaDevelopersHost, '/docs/Quickstart'),
      newTab: false,
      options: []
    },
    {
      name: t('Navigation.companyNavbar'),
      href: url(Config.palencaLandingHost, '/about'),
      newTab: false,
      options: [
        {
          name: t('Navigation.blog'),
          href: url(Config.palencaBlogHost),
          newTab: false
        },
        {
          name: t('Navigation.contactUs'),
          href: url(Config.palencaLandingHost, '/contacto'),
          newTab: false
        }
      ]
    }
  ]
  return (
    <div className="align-self-center fixed top-0 left-0 right-0 z-50 grid grid-flow-row auto-rows-auto items-center bg-[#fff] py-[12px] px-[16px] font-inter font-[350] md:px-[24px] lg:px-[72px]">
      <div className="col-span-6 row-start-1 flex gap-[12px] md:justify-between lg:col-span-2">
        <div onClick={handleOpenNavigation} className="lg:hidden">
          <img src="/images/menu-icon.svg" alt="Menu icon" />
        </div>
        <a href={url(Config.palencaLandingHost)}>
          <img
            src="/images/logos/logo_palenca.svg"
            className="w-[112px] max-w-none lg:h-8 lg:w-[150px]"
            alt="Palenca logo"
          />
        </a>
      </div>
      {/* mobile sections */}
      {isNavigationOpen && (
        <div className="col-span-12 row-start-2 mt-[16px] gap-[40px] p-[9px] text-[#49454f] lg:col-span-10 lg:row-start-1 lg:mt-[0] lg:flex lg:hidden lg:justify-center">
          {navigation.map((item) => (
            <div key={item.name}>
              <div
                className="justify-space-between grid-cols-auto grid border-b-[1px] py-[18px] lg:relative lg:border-none lg:py-[0]"
                onClick={() => openMenu(item.name)}
              >
                <div className="col-span-6 row-start-1">{item.name}</div>
                <div className="col-span-6 row-start-1 justify-self-end">
                  {item.options.length > 0 && <CaretIcon />}
                </div>
                <div className="col-span-12 lg:absolute lg:top-[16px] lg:mt-[9px] lg:w-[280px] lg:rounded-[9px] lg:bg-[#fff] lg:px-[9px] lg:shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]">
                  {open &&
                    item.name == currentOption &&
                    item.options.map((option) => (
                      <div
                        className="mt-[9px] mb-[12px] p-[9px] lg:rounded-[9px] lg:p-[12px] lg:hover:bg-[#eaeef3]"
                        key={option.name}
                      >
                        {option.name}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {/* desktop sections */}
      <div className="hidden gap-[40px] p-[9px] text-[#49454f] lg:col-span-10 lg:row-start-1 lg:mt-[0] lg:block lg:flex lg:justify-center lg:p-[0px]">
        {navigation.map((item) => (
          <div
            key={item.name}
            onMouseEnter={() => {
              setOpen(true)
              setCurrentOption(item.name)
            }}
          >
            <div
              className="justify-space-between grid-cols-auto grid border-b-[1px] py-[18px] lg:relative lg:border-none lg:py-[0] items-center"
              onClick={() => openMenu(item.name)}
            >
              <a href={item.href} className="col-span-6 row-start-1">
                {item.name}
              </a>
              <div className="col-span-6 row-start-1 justify-self-end">
                {item.options.length > 0 && <CaretIcon />}
              </div>
              <div
                onMouseLeave={() => setOpen(false)}
                className="col-span-12 lg:absolute lg:top-[16px] lg:mt-[9px] lg:w-[280px] lg:rounded-[9px] lg:bg-[#fff] lg:px-[9px] lg:shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]"
              >
                {open &&
                  item.name == currentOption &&
                  item.options.map((option) => (
                    <a
                      href={option.href}
                      className="mt-[9px] mb-[12px] block p-[9px] lg:rounded-[9px] lg:p-[12px] lg:hover:bg-[#eaeef3]"
                      key={option.name}
                    >
                      {option.name}
                    </a>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="col-span-6 row-start-1 justify-self-end lg:col-span-2">
        <a
          href={url(Config.palencaDashboardHost)}
          className="hidden rounded-[8px] border-[1px] border-[#76767f] py-[10px] px-[20px] text-center text-[11px] text-[#2b46b9] lg:mr-[24px] lg:inline-block lg:text-[15px]"
        >
          {t('Navigation.signIn')}
        </a>
        <a
          href={url(Config.palencaLandingHost, '/contacto')}
          className="inline-block rounded-[8px] bg-[#2b46b9] py-[12px] px-[20px] text-[11px] text-[#fff] lg:text-[15px]"
        >
          {t('Navigation.requestDemo')}
        </a>
      </div>

      {isNavigationOpen && (
        <>
          <a
            href={url(Config.palencaLandingHost, '/contacto')}
            className="col-span-12 mb-[20px] rounded-[8px] bg-[#2b46b9] py-[10px] px-[20px] text-center text-[18px] text-[11px] text-[#fff] lg:hidden"
          >
            {t('Navigation.requestDemo')}
          </a>
          <a
            href={url(Config.palencaDashboardHost)}
            className="col-span-12 rounded-[8px] border-[1px] border-[#76767f] py-[10px] px-[20px] text-center text-[18px] text-[#2b46b9] lg:hidden"
          >
            {t('Navigation.signIn')}
          </a>
        </>
      )}
    </div>
  )
}
