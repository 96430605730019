const isProduction = process.env.NODE_ENV === 'production'

export const Config = {
  isProduction: isProduction,
  companyName: 'Palenca',
  palencaSandboxHost: 'https://sandbox.palenca.com',
  palencaApiHost: 'https://api-connect.palenca.com',
  palencaPrivateApiKey: `${process.env.PALENCA_PRIVATE_API_KEY}`,
  palencaPublicApiKey: `${process.env.NEXT_PUBLIC_PALENCA_PUBLIC_API_KEY}`,
  palencaWidgetId: `${process.env.NEXT_PUBLIC_PALENCA_WIDGET_ID}`,
  baseUrl: 'https://demo.palenca.com',
  linkedinHref: 'https://www.linkedin.com/company/palenca',
  twitterHref: 'https://twitter.com/PalencaAPI',
  instagramHref: 'https://www.instagram.com/PalencaAPI',
  palencaLandingHost: `${
    process.env.NEXT_PUBLIC_PALENCA_LANDING_HOST || 'https://palenca.com'
  }`,
  palencaDemoHost: `${
    process.env.NEXT_PUBLIC_PALENCA_DEMO_HOST || 'https://demo.palenca.com'
  }`,
  palencaDashboardHost: `${
    process.env.NEXT_PUBLIC_PALENCA_DASHBOARD_HOST ||
    'https://dashboard.palenca.com'
  }`,
  palencaDevelopersHost: `${
    process.env.NEXT_PUBLIC_PALENCA_DEVELOPERS_HOST ||
    'https://developers.palenca.com/'
  }`,
  palencaStatusHost: `${
    process.env.NEXT_PUBLIC_PALENCA_DEVELOPERS_HOST ||
    'https://www.status.palenca.com/'
  }`,
  palencaBlogHost: `${
    process.env.NEXT_PUBLIC_PALENCA_BLOG_HOST || 'https://blog.palenca.com/'
  }`,
  palencaNotionHost: `${
    process.env.NEXT_PUBLIC_PALENCA_NOTION_HOST || 'https://palenca.notion.site'
  }`
}
