import React, { useCallback, useMemo, useRef, useState } from 'react'
import { GetStaticProps, NextPage } from 'next'
import { useTranslation } from 'next-i18next'
import { Container, Loading } from '../components'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import nextI18NextConfig from '../next-i18next.config'
import { RefreshIcon, ChartBarIcon } from '@heroicons/react/outline'

import { Config } from '../data/config'
import {
  EnvironmentOption,
  EnvironmentType,
  WidgetEnvironments
} from '../components/Widget/WidgetEnvironments'
import { WidgetSteps } from '../components/Widget/WidgetSteps'
import { loadLink, PalencaLinkReact } from '@palenca/palenca-link'
import { RenderOptions } from '@palenca/palenca-link/src/types'
import axios from 'axios'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dracula } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import { dummyEarnings, platformsWithEarnings } from '../misc/dummyData'

const linkPromise = loadLink(Config.palencaPublicApiKey, Config.palencaWidgetId)

interface UserData {
  account_id: string
  country: string
  next_step: null
  platform: string
  user_id: string
}

interface Message<T> {
  data: T
}

const Demo: NextPage = () => {
  const { t } = useTranslation()
  const responseRef = useRef<HTMLDivElement>(null)
  const insightsRef = useRef<HTMLDivElement>(null)
  const [environment, setEnvironment] = useState<EnvironmentType>(
    EnvironmentType.sandbox
  )
  const [response, setResponse] = useState<string>('')
  const [insightsResponse, setInsightsResponse] = useState<string>('')
  const [accountId, setAccountId] = useState<string>('')
  const [platform, setPlatform] = useState<string>('')
  const [showResult, setShowResult] = useState<boolean>(false)
  const [showInsights, setShowInsights] = useState<boolean>(false)
  const [hasInsights, setHasInsights] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const options: RenderOptions = useMemo(
    () => ({
      configuration: {
        isSandbox: environment === EnvironmentType.sandbox
      },
      appearance: {}
    }),
    [environment]
  )

  const insightsPlatforms = ['imss', 'uber', 'didi', 'rappi']

  const scrollToResult = () => responseRef.current?.scrollIntoView()
  const scrollToInsights = () => insightsRef.current?.scrollIntoView

  const getAccountData = async (id: string) => {
    setIsLoading(true)

    try {
      const response = await axios.get(`/api/accounts/${id}`, {
        headers: {
          'x-environment': `${environment}`
        }
      })

      return response.data
    } finally {
      setIsLoading(false)
    }
  }

  const getInsightsData = async (id: string, platform: string) => {
    setIsLoading(true)

    try {
      const response = await axios.get(`/api/accounts/${id}/insights/${platform}`, {
        headers: {
          'x-environment': `${environment}`
        }
      })

      return response.data
    } finally {
      setIsLoading(false)
    }
  }

  const handleEnvironmentChange = (env: EnvironmentOption) => {
    setShowResult(false)
    setHasInsights(false)
    setShowInsights(false)
    setResponse('')
    setInsightsResponse('')
    setEnvironment(env.type)
  }

  const handleEvent = async (event: string, message: Message<UserData>) => {
    if (event === 'connection_success') {
      const { data } = message
      const currentAccountId = data.account_id
      setShowResult(true)
      scrollToResult()
      setAccountId(currentAccountId)
      await fetchData(currentAccountId)
    }
  }

  const fetchData = async (id: string) => {
    const account = await getAccountData(id)
    const { data } = account;
    const country = data.account.country;
    const platform = data.account.platform;
    const earnings = data.earnings.earnings;

    setPlatform(platform)
    setHasInsights(insightsPlatforms.includes(platform) && environment === EnvironmentType.production)

    // this is only because some team members reported cases where earnings array is empty (should never be).
    // it happens rarely, so this is a fallback with dummy data just in case
    if (earnings.length === 0 && platformsWithEarnings.includes(platform)) {
      data.earnings.earnings = dummyEarnings[country as keyof typeof dummyEarnings];
    }

    setResponse(JSON.stringify(account, null, 2))
  }

  const fetchInsights = async (id: string, platform: string) => {
    const insights = await getInsightsData(id, platform);
    setShowInsights(true)
    scrollToInsights()
    setInsightsResponse(JSON.stringify(insights, null, 2))
  }

  const PalencaLink = useCallback(
    () => (
      <PalencaLinkReact
        key={environment}
        link={linkPromise}
        options={options}
        onEvent={handleEvent}
      />
    ),
    [environment]
  )

  return (
    <Container
      title={t('SEO.Demo.title')}
      description={t('SEO.Demo.description')}
    >
      <div className="mx-auto flex max-w-2xl flex-col items-center justify-center px-4 md:max-w-3xl lg:max-w-4xl lg:px-0 xl:max-w-6xl">
        <h2 className="text-4xl font-semibold text-black sm:text-4xl xl:text-5xl">
          {t('Demo.Hero.title')}
        </h2>
        <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 gap-10 lg:max-w-none lg:grid-cols-2">
          <div className="order-1 mx-auto w-full max-w-md">
            <WidgetEnvironments onChange={handleEnvironmentChange} />
            <WidgetSteps title={t('Demo.Steps.title')} />
          </div>
          <div className="order-2 min-h-[700px] w-full max-w-md lg:min-h-0">
            <PalencaLink />
          </div>
        </div>
      </div>
      <div ref={responseRef}>
        {showResult && (
          <div className="mx-auto flex min-h-[36rem] max-w-2xl flex-col items-center px-4 pt-16 md:max-w-3xl lg:max-w-4xl lg:px-0 lg:pt-24 xl:max-w-6xl">
            <div className="mt-4 flex w-full max-w-2xl items-center justify-between sm:mt-5 lg:mt-6">
              <h2 className="text-3xl font-medium tracking-tight text-black sm:text-4xl xl:text-5xl">
                {t('Demo.Response.title')}
              </h2>
              <div className="flex items-center justify-between sm:mt-5 lg:mt-6">
                {hasInsights && (
                  <button
                    onClick={() => fetchInsights(accountId, platform)}
                    className="inline-flex transform items-center justify-center rounded-lg border border-primary-blue-900 bg-white px-5 py-2 text-base font-semibold text-primary-blue-900 transition duration-300 hover:border-primary-blue-900 hover:text-primary-blue-900 mr-2"
                  >
                    <ChartBarIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="ml-3">Insights</span>
                  </button>
                )}
                <button
                  onClick={() => fetchData(accountId)}
                  className="inline-flex transform items-center justify-center rounded-lg border border-primary-blue-900 bg-white px-5 py-2 text-base font-semibold text-primary-blue-900 transition duration-300 hover:border-primary-blue-900 hover:text-primary-blue-900 ml-2"
                >
                  <RefreshIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
            {isLoading ? (
              <div className="h-[28rem] w-full max-w-2xl">
                <Loading />
              </div>
            ) : (
              <>
                <div className="mt-8 max-h-[36rem] w-full max-w-2xl overflow-scroll rounded-lg">
                  {showInsights && (
                    <>
                      <p className="mt-4 text-base text-2xl">Insights</p>
                      <SyntaxHighlighter language="json" style={dracula}>
                        {insightsResponse}
                      </SyntaxHighlighter>
                    </>
                  )}
                  <p className="mt-4 text-base text-2xl">Data</p>
                  <SyntaxHighlighter language="json" style={dracula}>
                    {response}
                  </SyntaxHighlighter>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Container>
  )
}

export default Demo

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale!, ['common'], nextI18NextConfig))
    }
  }
}
