import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { Config } from '../../data/config'
import { Navigation } from './Navigation'
import { Footer } from './Footer'

export interface ContainerProps {
  title?: string
  description?: string
  image?: string
  type?: string
  date?: string
  children: React.ReactNode
}

export const Container = ({
  title,
  description,
  image,
  type,
  date,
  children
}: ContainerProps) => {
  const router = useRouter()
  const meta = {
    title: title ? `${title} - ${Config.companyName}` : Config.companyName,
    description: description ? description : `The only Payroll API in LatAm`,
    image: image
      ? image
      : 'https://www.palenca.com/images/default_og_image.jpeg',
    type: type ? type : 'website',
    date
  }

  return (
    <div className="bg-white">
      <Head>
        <title>{meta.title}</title>
        <meta name="robots" content="follow, index" />
        <meta content={meta.description} name="description" />
        <meta property="og:url" content={`${Config.baseUrl}${router.asPath}`} />
        <link rel="canonical" href={`${Config.baseUrl}${router.asPath}`} />
        <meta property="og:type" content={meta.type} />
        <meta property="og:site_name" content="Palenca" />
        <meta property="og:description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:image" content={meta.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@palenca" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.image} />
        {meta.date && (
          <meta property="article:published_time" content={meta.date} />
        )}
      </Head>
      <div className="flex min-h-screen w-full flex-col overflow-hidden font-poppins">
        <Navigation />
        <div className="mt-[70px] flex-grow flex-grow pt-20">{children}</div>
        <Footer />
      </div>
    </div>
  )
}
